export const milestones = [
  {
    id: 1,
    year: 2013,
    title: "In The Beginning",
    description:
      "At the age of 14, Amadeus started working in his mother's beauty salon.",
  },
  {
    id: 2,
    year: 2017,
    title: "Architectural Training",
    description:
      "At the age of 18, Amadeus was academically trained as an architect. Amadeus' clientele grew exponentially as he applied his architectural knowledge of volumes and space to hair, faces, and the body of his clients. Inspired by Lavoisier's principle 'nothing is lost, nothing is gained, but everything gets transformed,' Amadeus applied this concept to his work in beauty.",
  },
  {
    id: 3,
    year: 2018,
    title: "The Beauty Factory",
    imgs: [
      { id: 1, src: "/salon-1.jpg" },
      { id: 2, src: "/salon-2.jpg" },
      { id: 3, src: "/salon-3.jpg" },
      { id: 4, src: "/salon-4.jpg" },
    ],
    description:
      "In 2018, Amadeus Sabra launched The Beauty Factory, a minimal, industrial-style construction with greenery in a double-floored structure. The Beauty Factory blends Bauhaus, minimal architecture, and Jean Prouvé's prefabricated elements, creating a decluttered, purposeful space that reflects the concept of 'Now and Zen.'",
  },
  {
    id: 4,
    year: null,
    title: "AMADubai Soon",
    description:
      "Sailing towards new ports, the Amadeus Sabra brand is conquering DUBAI soon. Indeed, plans are afoot to establish the brand in Dubai and carry the same ideals and ideas which the Lebanese branch has been built on.",
  },
];

export const storyContent = [
  "Born",
  "in",
  "1989",
  "Amadeus",
  "Sabra",
  ",",
  "was",
  "raised",
  "in",
  "Lebanon's",
  "Bekaa",
  "Valley",
  ",",
  "an",
  "open-space",
  "soothing",
  "yet",
  "also",
  "challenging",
  "that",
  "the",
  "Romans",
  "deemed",
  "worthy",
  "enough",
  "to",
  "construct",
  "one",
  "of",
  "their",
  "greatest",
  "temples",
  "in",
  ".",
  "A",
  "temple",
  "that",
  "has",
  "hosted",
  "the",
  "works",
  "of",
  "so",
  "many",
  "musical",
  "geniuses",
  ",",
  "including",
  "a",
  "certain",
  "Wolfgang",
  "Amadeus",
  "Mozart",
  "-",
  '"Don',
  'Juan"',
  ",",
  "1965",
  ".",
];
