import React from "react";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";

export function ModeToggle({
  scrolled,
  drawer,
}: {
  scrolled: boolean;
  drawer?: boolean;
}) {
  const { setTheme } = useTheme();
  const theme = window.localStorage.getItem("theme");
  const [showSun, setShowSun] = React.useState(
    theme === "light" ? false : true
  );

  function changeTheme(theme: string) {
    if (theme === "light") {
      setTheme("light");
      setShowSun(false);
    } else {
      setTheme("dark");
      setShowSun(true);
    }
  }
  return (
    <>
      <div
        onClick={() => changeTheme("light")}
        className={
          showSun ? "block cursor-pointer" : "hidden"
        }
      >
        <Sun
          className={`h-5 w-5 ${
            scrolled
              ? `outline-none dark:hover:fill-neutral-200 dark:text-neutral-200 hover:fill-neutral-900 text-neutral-900 `
              : "text-neutral-100 hover:fill-neutral-100"
          } ${
            drawer && "text-neutral-100 fill-neutral-100"
          }`}
          onClick={() => changeTheme("light")}
        />
      </div>
      <div
        onClick={() => changeTheme("dark")}
        className={
          showSun ? "hidden" : "block cursor-pointer"
        }
      >
        <Moon
          className={`h-5 w-5 ${
            scrolled
              ? ` outline-none dark:hover:fill-neutral-200 hover:fill-neutral-900 text-neutral-900 `
              : "text-neutral-200 hover:fill-neutral-200"
          } ${
            drawer && "text-neutral-900 fill-neutral-900"
          }`}
        />
      </div>
    </>
  );
}
