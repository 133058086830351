import React, { useState, useEffect } from "react";

function Intro() {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Show logo for 1.5 seconds before starting animation
    const startAnimation = setTimeout(() => {
      setIsAnimating(true);
      // Start fade out after animation completes
      setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    }, 750);

    return () => clearTimeout(startAnimation);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-neutral-900 flex items-center justify-center z-50">
      <div
        className={`transition-all duration-4000 ease-in-out transform ${
          isAnimating
            ? "scale-[150] opacity-10"
            : "scale-100 opacity-100"
        }`}
      >
        <h1 className="font-trajan lg:text-6xl text-xl text-neutral-100">
          AMADEUS SABRA
        </h1>
      </div>
    </div>
  );
}

export default Intro;
