import React from "react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { useNavigate } from "react-router-dom";
import { LoaderIcon } from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import { getResetLink } from "../../../src/lib/utils";

export default function LostPassword() {
  const { toast } = useToast();
  const [email, setEmail] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const nav = useNavigate();

  React.useEffect(() => {
    const listener = (event: any) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter"
      ) {
        // tryLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email]);
  async function resetAndRedirect(e: any) {
    e.preventDefault();
    setLoader(true);
    const res = await getResetLink(email);
    if (res.data.status !== 200) {
      setLoader(false);
      toast({ title: res.message });
    } else {
      setLoader(false);
      nav(`/reset_pass/${email}`);
    }
  }
  return (
    <div className="w-screen h-dvh flex justify-center items-center flex-col p-10">
      <form
        onSubmit={(e) => resetAndRedirect(e)}
        className="shadow-2xl sm:w-96 w-full p-10 flex flex-col gap-1 items-center"
      >
        <img
          src="/logo-aaa.png"
          alt="logo"
          width={50}
          className="dark:invert mb-5"
        />
        <h1 className="font-bold text-center text-xl mb-5">
          RESET PASSWORD
        </h1>
        <p className="text-center text-neutral-600 text-sm mb-5">
          Please enter your email to reset your password
        </p>
        <Input
          name="email"
          required
          type="email"
          placeholder="Email"
          className=" text-neutral-900 focus:outline-none dark:text-neutral-200 rounded-none"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <Button
          className="mt-1 border w-full rounded-none"
          type="submit"
          // disabled={loader || email.length < 1}
        >
          {loader ? (
            <LoaderIcon className="animate-spin" />
          ) : (
            `RESET`
          )}
        </Button>

        <Button
          onClick={() => {
            nav("/login");
          }}
          className="mt-1 border w-full rounded-none"
          variant={"secondary"}
        >
          SIGN IN
        </Button>
      </form>
    </div>
  );
}
