import React, { useRef, useEffect, useState } from "react";
import { milestones } from "./content";
import MissionVision from "./missionVision";

export function Milestones() {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [moveToMileStones, setMoveToMilestones] =
    useState(false);
  const lastMileStone = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (lastMileStone.current) {
        if (scrollY > window.innerHeight + 2000) {
          setMoveToMilestones(true);
        } else {
          setMoveToMilestones(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () =>
      window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      ref={scrollRef}
      className="overflow-x-auto no-scrollbar snap-x snap-mandatory scroll-smooth"
    >
      <div
        className={`flex items-center space-x-16 min-w-max `}
      >
        {/* Line passing through milestones */}
        <div className="absolute w-screen top-1/2 left-0 right-0 h-0.5 bg-neutral-700 dark:bg-neutral-700"></div>
        {milestones.map((milestone, index) => {
          const isLast = index === 3;

          return (
            <div
              key={milestone.id}
              className={`w-screen flex flex-col items-center justify-center snap-always snap-center overflow-y-auto `}
              ref={isLast ? lastMileStone : null}
            >
              <div
                className={`flex h-full sm:flex-row flex-col justify-start sm:items-start items-center w-full sm:w-2/3 gap-5 p-10 items-center`}
              >
                <img
                  src={`/salon-${milestone.id}.jpg`}
                  alt=""
                  className=" w-48"
                />
                <div className="w-fit h-full bg-neutral-900 flex flex-col justify-center items-center sm:items-start">
                  <h3 className="sm:text-xl text-md text-neutral-200 font-trajan">
                    {milestone.title.toUpperCase()}
                  </h3>
                  <span className="text-neutral-200 font-trajan sm:text-xl text-md">
                    {milestone.year}
                  </span>
                  <p className="text-sm text-neutral-400 sm:text-left text-center">
                    {milestone.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={`duration-700 flex items-center justify-center fixed top-0 w-screen bg-neutral-900 overflow-x-scroll ${
          !moveToMileStones ? "translate-y-[100vh]" : ""
        }`}
      >
        <MissionVision />
      </div>
    </div>
  );
}
