import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import React from "react";
import { priceListMenData } from "../priceList";
import { ArrowUpRightFromSquare } from "lucide-react";

export function LebanonMen() {
  const nav = useNavigate();
  const phone = "+9613976780";
  const message =
    "Greetings! I would like to make an appointment for this service: ";
  return (
    <div className="flex flex-col w-full h-100 font-montserrat  sm:mt-36 mt-16 p-5 gap-5">
      <h2 className="text-xl sm:text-2xl font-montserrat font-medium ">
        AMADEUS MEN
      </h2>
      <div className="w-full lg:pl-32 lg:pr-32 p-5 flex flex-col sm:flex-row">
        <div className="w-full sm:w-2/3">
          {priceListMenData.map((item: any) => (
            <Accordion
              type="single"
              key={item.category}
              collapsible
              className="w-full"
            >
              <AccordionItem
                value="item-1"
                className="w-full"
              >
                <AccordionTrigger className="text-lg">
                  {item.category}
                </AccordionTrigger>
                <div className="flex gap-5 flex-wrap flex-col">
                  {item.items.map((service: any) => (
                    <AccordionContent
                      key={service.service}
                      className="flex border-b rounded-none justify-start items-center gap-1 text-sm rounded-none pl-2 pb-1 font-light pt-5"
                    >
                      <a
                        className="flex gap-1 items-center grow hover:underline"
                        href={`https://wa.me/${phone}?text=${
                          message +
                          "Men" +
                          " " +
                          service.service
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="font-bold">-</span>

                        <span className="hover:underline">
                          {service.service}
                        </span>
                      </a>
                      <ArrowUpRightFromSquare className="w-3" />
                    </AccordionContent>
                  ))}
                </div>
              </AccordionItem>
            </Accordion>
          ))}
        </div>
        <div className="flex flex-col gap-5 justify-end sm:right-32 right-10 p-5 items-center w-full sm:w-1/5 sm:fixed sm:mt-0 mt-5">
          <video
            width="200px"
            autoPlay
            muted
            playsInline
            loop
            controls={false}
          >
            <source
              src={"/amadeus-videos/amadeus-ad-1.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <p className="text-sm text-center">
            View our products to match your required service
          </p>
          <Button
            className="rounded-none w-36"
            onClick={() => {
              nav("/shop");
            }}
          >
            SHOP NOW
          </Button>
        </div>
      </div>
    </div>
  );
}
