import { ArrowDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { storyContent } from "./content";
import "./about.css";
import BackButton from "../../../src/components/backButton";
import { Milestones } from "./milestones";
import Intro from "./intro";
import { useTheme } from "next-themes";

export default function About() {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [scrollingEnded, setScrollingEnded] =
    useState(false);
  const { setTheme } = useTheme();
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight + 3000;
      const maxScroll =
        document.body.clientHeight - windowHeight;
      const progress = Math.min(
        scrollPosition / maxScroll,
        1
      );
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () =>
      window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const lastIndex = textRefs.current.length - 1;

    textRefs.current.forEach((span, index) => {
      if (span) {
        const wordProgress =
          (index + 1) / textRefs.current.length;
        const opacity =
          scrollProgress >= wordProgress ? 1 : 0;
        span.style.setProperty(
          "--word-opacity",
          opacity.toString()
        );
        if (index === lastIndex && opacity === 1) {
          setScrollingEnded(true);
        } else {
          setScrollingEnded(false);
        }
      }
    });
  }, [scrollProgress]);

  useEffect(() => {
    const actualTheme = String(
      localStorage.getItem("theme")
    );

    if (actualTheme !== "dark") {
      setTheme("dark");
      localStorage.setItem("userTheme", "light");
    } else {
      setTheme("dark");
    }
  }, []);
  return (
    <div className="z-50 relative" id="about-section">
      <BackButton />
      <Intro />
      <div
        ref={containerRef}
        className={`fixed inset-0 flex flex-col items-center justify-center bg-neutral-900 overflow-hidden snap-y snap-mandatory `}
      >
        <p
          className={`font-trajan text-[16px] md:text-4xl text-center md:leading-[6rem] leading-[2rem] grow md:p-32 pt-32 pl-5 pr-5 ${
            scrollingEnded
              ? "-translate-y-[100vh] duration-700 delay-500"
              : "duration-700 delay-500"
          }`}
        >
          {storyContent.map((word, index) => {
            const isFirst = index === 0;
            return (
              <span
                key={index}
                ref={(el) => (textRefs.current[index] = el)}
                className={`inline-block mb-2 mr-3 transition-colors duration-300 ease-in-out word-shine ${
                  isFirst
                    ? "text-neutral-100"
                    : "text-neutral-700"
                }`}
              >
                {word}
              </span>
            );
          })}
        </p>
        {!scrollingEnded && (
          <div className="text-center md:fixed z-50 right-1/2 left-1/2 text-sm animate-bounce bottom-5 text-neutral-700 flex flex-col justify-center items-center">
            <span className="w-32">SCROLL DOWN</span>
            <ArrowDown />
          </div>
        )}

        <div
          className={`${
            !scrollingEnded
              ? "delay-500 absolute translate-y-[100vh] duration-700 w-screen flex justify-center items-center h-screen"
              : "delay-500 duration-700 bg-neutral-900 w-screen flex justify-center items-center h-screen absolute snap-always snap-center"
          }`}
        >
          <Milestones />
        </div>
      </div>
    </div>
  );
}
