import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import Venue from "./venue";

export default function MissionVision() {
  const [secondDivVisible, setSecondDivVisible] =
    React.useState(false);
  const [thirdDivVisible, setThirdDivVisible] =
    React.useState(false);
  const missionDiv = React.useRef<HTMLDivElement | null>(
    null
  );
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > window.innerHeight + 2500) {
        setSecondDivVisible(true);
      } else {
        setSecondDivVisible(false);
      }
      if (scrollY > window.innerHeight + 3700) {
        setThirdDivVisible(true);
      } else {
        setThirdDivVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="h-screen flex justify-center items-center z-50"
      ref={missionDiv}
    >
      <img
        src="./header.jpeg"
        alt=""
        className="absolute top-0 left-0 w-screen h-full opacity-20 z-50"
      />
      <div
        className={`flex w-screen justify-center lg:gap-32 lg:p-32 gap-10 p-10 items-center h-screen overflow-hidden ${
          secondDivVisible
            ? "-translate-y-[100vh] duration-700"
            : "duration-700"
        }`}
      >
        <img
          src="./salon-2.jpg"
          alt=""
          className="md:block hidden z-50 border border-neutral-500 w-96"
        />
        <Accordion
          type="single"
          collapsible
          className="w-full text-neutral-200 z-50"
        >
          <AccordionItem
            value="mission"
            className="mb-5 dark:border-b dark:border-b-neutral-300"
          >
            <AccordionTrigger className="md:text-4xl text-2xl font-trajan hover:no-underline hover:text-neutral-500 ">
              Mission
            </AccordionTrigger>
            <AccordionContent className="pt-5 text-neutral-200 font-light">
              Providing clients with a high-end experience,
              while using top-notch products, modern
              techniques, and music as well as smell to
              invigorate their senses. A total mindfulness
              experience based on the idea of a "me-time"
              for the client
            </AccordionContent>
          </AccordionItem>
          <AccordionItem
            value="vision"
            className="mb-5 dark:border-b dark:border-b-neutral-300"
          >
            <AccordionTrigger className="md:text-4xl text-2xl font-trajan hover:no-underline hover:text-neutral-500">
              Vision
            </AccordionTrigger>
            <AccordionContent className="pt-5 text-neutral-200 font-light">
              To redefine beauty while adapting it
              differently from one client to another, and to
              provide each client with an unforgettable
              experience that detaches them from their
              day-to-day lives into a soothing ambiance.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem
            value="values"
            className="mb-5 dark:border-b dark:border-b-neutral-300"
          >
            <AccordionTrigger className="md:text-4xl text-2xl font-trajan hover:no-underline hover:text-neutral-500">
              Values
            </AccordionTrigger>
            <AccordionContent className="flex gap-10 flex-col pt-5 text-neutral-200 overflow-x-scroll no-scrollbar sm:max-h-full max-h-[370px]">
              <div className="w-full flex flex-col gap-3">
                <h3 className="md:text-xl text-md font-bold">
                  Integrity
                </h3>
                <p className="md:text-md text-sm font-light">
                  is one of our main assets, the name
                  "Amadeus Sabra" and the brand are spotless
                  in the market and provide us with the
                  backbone of our notoriety.
                </p>
              </div>
              <div className="w-full flex flex-col gap-3">
                <h3 className="md:text-xl text-md font-bold">
                  Adaptability and Efficiency
                </h3>
                <p className="md:text-md text-sm font-light">
                  We adapt our techniques when it comes to
                  individual client needs, and value time
                  management while servicing several clients
                  at a time, as we stick to rigid timetables
                  particularly when big events such as
                  weddings are involved.
                </p>
              </div>
              <div className="w-full flex flex-col gap-3">
                <h3 className="md:text-xl text-md font-bold">
                  Teamwork and Continual Improvement
                </h3>
                <p className="md:text-md text-sm font-light">
                  never resting on our laurels especially
                  when new trends are coming out seasonally,
                  when new techniques and products are
                  launched monthly, but also striving for
                  our team to feel at ease with each other,
                  to be able to complement each other's
                  skills and improve their own output.
                </p>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <div
        className={` flex items-center justify-center z-50 fixed left-0 w-full h-screen bg-neutral-900 transition-transform duration-700 ${
          !secondDivVisible ? "translate-y-[100vh]" : ""
        } ${
          thirdDivVisible
            ? "-translate-y-[100vh] duration-700"
            : "duration-700"
        }`}
      >
        <div className="w-full flex flex-col items-center lg:p-32 pt-5 pl-5 pr-5 gap-10">
          <div className="flex flex-col gap-5">
            <h2 className="lg:text-6xl text-xl text-center font-trajan text-neutral-200">
              {`Amadeus Sabra The Beauty Factory`.toLocaleUpperCase()}
            </h2>
            <p className="text-neutral-500 italic text-center text-sm">
              Amadeus Sabra the Beauty Factory is a
              fashionable and modern venue for all beauty
              lovers
            </p>
          </div>

          <Venue floor={1} img={3} />
        </div>
      </div>
      <div
        className={`z-50 fixed left-0 w-full h-screen bg-neutral-900  flex items-center justify-center transition-transform duration-700 ${
          !thirdDivVisible ? "translate-y-[100vh]" : ""
        }`}
      >
        <div className="w-full h-fit flex flex-col items-center lg:p-32 pt-5 pl-5 pr-5 gap-10">
          <div className="flex flex-col gap-5">
            <h2 className="lg:text-6xl text-xl text-center font-trajan text-neutral-200">
              {`Amadeus Sabra The Beauty Factory`.toLocaleUpperCase()}
            </h2>
            <p className="text-neutral-500 italic text-center text-sm">
              Amadeus Sabra the Beauty Factory is a
              fashionable and modern venue for all beauty
              lovers
            </p>
          </div>

          <Venue floor={2} img={4} />
        </div>
      </div>
    </div>
  );
}
