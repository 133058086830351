import React from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";
import { MenuIcon } from "lucide-react";
import { wooCategory } from "../lib/interfaces";
import { Link } from "react-router-dom";
import { WooCommerce } from "../lib/api";
import { useQuery } from "react-query";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { ModeToggle } from "./themeToggle";
import SocialIcons from "./socialIcons";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
export function LeftDrawer({
  scrolled,
}: {
  scrolled: boolean;
}) {
  const hairCareCategories = [
    { id: 1, name: "Shampoo", categoryId: 375 },
    { id: 2, name: "Conditioner", categoryId: 373 },
    { id: 3, name: "Mask", categoryId: 371 },
    { id: 4, name: "Oils And Serum", categoryId: 366 },
    { id: 5, name: "Treatment", categoryId: 410 },
  ];

  const { data: tags } = useQuery(["tags"], async () => {
    const res = WooCommerce.get(
      "products/tags/?per_page=100"
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
    return res;
  });
  const { data: categories } = useQuery(
    ["wooCategories"],
    async () => {
      const res: wooCategory[] = WooCommerce.get(
        "products/categories/?per_page=100"
      )
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );
  return (
    <Sheet aria-describedby="left drawer">
      <SheetTrigger name="burger menu">
        <MenuIcon
          className={
            scrolled
              ? "h-5 w-5 mt-[1px] "
              : "text-neutral-200 h-5 w-5 mt-[1px] "
          }
        />
      </SheetTrigger>
      <SheetContent
        side={"left"}
        className="outline-none flex flex-col p-3 h-scree"
        aria-describedby="hairCare"
      >
        <SheetHeader>
          <SheetTitle className="flex justify-center mt-2">
            <img
              src="/logo-aaa.png"
              alt=""
              className={"dark:invert w-12"}
            />
          </SheetTitle>
        </SheetHeader>
        <Tabs
          defaultValue="hair-care"
          className="font-montserrat outline-none focus:outline-none flex flex-col"
          aria-describedby="hairCare"
        >
          <TabsList className="w-full rounded-none outline-none focus:outline-none">
            <TabsTrigger
              value="hair-care"
              className="w-full pt-2 rounded-none"
            >
              HAIR CARE
            </TabsTrigger>
            <TabsTrigger
              value="brands"
              className="w-full pt-2 rounded-none"
            >
              BRANDS
            </TabsTrigger>
          </TabsList>

          <TabsContent
            value="hair-care"
            className="outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-none focus-visible:ring-offset-0 "
          >
            <Accordion
              type="single"
              collapsible
              className="w-full"
            >
              {hairCareCategories.map((category) => (
                <AccordionItem
                  key={category.id}
                  value={category.name}
                  className="dark:border-b dark:border-b-neutral-800 mt-5 pb-3 data-[state=open]:border-b-0"
                >
                  <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                    <SheetClose
                      asChild
                      className={`flex justify-between hover:underline cursor-pointer items-center font-montserrat w-fit`}
                    >
                      <Link
                        to={`/shop/category/${
                          category.categoryId
                        }/${encodeURIComponent(
                          category.name
                        )}`}
                      >
                        {category.name.toLocaleUpperCase()}
                      </Link>
                    </SheetClose>
                  </AccordionTrigger>
                  <div className="sm:max-h-[600px] max-h-[150px] overflow-scroll no-scrollbar">
                    {tags
                      ?.filter((tag: any) =>
                        tag.name.includes(category.name)
                      )
                      .map((sub: any) => (
                        <AccordionContent
                          key={sub.id}
                          className="font-light text-[12px] border-b p-2 cursor-pointer border-b border-b-neutral-200 dark:border-b-neutral-800"
                        >
                          <Link
                            to={`/shop/hairType/${sub.id}/${sub.name}`}
                            className="hover:underline cursor-pointer font-montserrat text-[12px]"
                          >
                            <SheetClose className="flex justify-between items-center w-full">
                              {sub.name}
                            </SheetClose>
                          </Link>
                        </AccordionContent>
                      ))}
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </TabsContent>
          <TabsContent
            value="brands"
            className="outline-none focus:outline-none"
          >
            <Accordion
              type="single"
              collapsible
              className="w-full"
            >
              {categories
                ?.filter((cat) => cat.parent === 367)
                .map((category) => (
                  <AccordionItem
                    value={category.name}
                    key={category.id}
                    className={`dark:border-b dark:border-b-neutral-800 mt-5 pb-3 data-[state=open]:border-b-0`}
                  >
                    <AccordionTrigger className="hover:no-underline hover:text-neutral-500 p-0 pb-2 font-normal">
                      <SheetClose
                        asChild
                        className={`flex justify-between hover:underline cursor-pointer items-center ont-montserrat w-fit`}
                      >
                        <Link
                          to={`/shop/category/${
                            category.id
                          }/${encodeURIComponent(
                            category.name
                          )}`}
                        >
                          {category.name.toLocaleUpperCase()}
                        </Link>
                      </SheetClose>
                    </AccordionTrigger>
                    <div className="sm:max-h-[600px] max-h-[250px] overflow-scroll no-scrollbar">
                      {categories
                        ?.filter(
                          (sub) =>
                            sub.parent === category.id
                        )
                        .map((sub) => {
                          return (
                            <AccordionContent
                              key={sub.id}
                              className="font-light text-[12px] border-b p-2 cursor-pointer border-b border-b-neutral-200 dark:border-b-neutral-800"
                            >
                              <Link
                                to={`/shop/category/${sub.id}/${sub.name}`}
                                className="hover:underline cursor-pointer font-montserrat text-[12px]"
                              >
                                <SheetClose className="flex justify-between items-center w-full">
                                  {sub.name}
                                </SheetClose>
                              </Link>
                            </AccordionContent>
                          );
                        })}
                    </div>
                  </AccordionItem>
                ))}
            </Accordion>
          </TabsContent>
        </Tabs>
        <div className="flex justify-between items-end grow h-fit bg-white dark:bg-neutral-900">
          <div className=" sm:hidden block w-full">
            <ModeToggle scrolled={scrolled} drawer />
          </div>
          <SocialIcons drawer />
        </div>
      </SheetContent>
    </Sheet>
  );
}
