import { Button } from "../../../src/components/ui/button";

export default function HomeBanner() {
  const phone = "+9613976780";
  const message =
    "Greetings! I would like to make an appointment";
  return (
    <div className="relative flex justify-center items-center">
      <img
        src={"/banner-mobile.webp"}
        alt="banner"
        className="w-screen"
      />
      <div className="absolute font-montserrat sm:w-[600px] w-40 flex flex-col gap-2 sm:gap-5 right-0 items-center sm:items-start">
        <p className="sm:text-2xl font-medium text-md dark:text-neutral-900 font-montserrat">
          SPECIAL OFFER
        </p>
        <p className="text-[12px] sm:text-sm dark:text-neutral-900 font-light sm:block hidden">
          Enjoy a complimentary Cream & 3 Best Selling
          Samples.
        </p>
        <Button
          onClick={() =>
            window.open(
              `https://wa.me/${phone}?text=${message}`
            )
          }
          className="rounded-none sm:w-36 w-28 text-[12px] sm:text-[14px] h-full dark:bg-neutral-800 dark:text-neutral-100 hover:dark:bg-neutral-700"
        >
          BOOK NOW
        </Button>
      </div>
    </div>
  );
}
