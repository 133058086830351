import React, { useState } from "react";
import { useQuery } from "react-query";
import { wooProduct } from "../products/productInterface";
import { WooCommerce } from "../../../src/lib/api";
import { Link, useParams } from "react-router-dom";
import { Input } from "../../../src/components/ui/input";
import AddToCart from "../../../src/components/addToCart";
import {
  CopyCheck,
  ExternalLinkIcon,
  Share2,
} from "lucide-react";
import MyLoader from "../../../src/components/MyLoader";
import { toast } from "../../../src/components/ui/use-toast";
import { Breadcrumbs } from "../../../src/components/breadcrumbs";
import useWishlist from "../../../src/hooks/useWishlist";
import Wishlist from "../../components/Wishlist";
import HomeProducts from "../products/homeProducts";

// type variation = Record<string, string>;
export default function Product() {
  const {
    categoryId,
    categoryName,
    productId,
    productName,
  } = useParams<{
    categoryId: string;
    categoryName: string;
    productId: string;
    productName: string;
  }>();
  const [quantity, setQuantity] = useState("1");
  const [mainImgSrc, setMainImgSrc] = useState("");
  // const [attribtues, setAttributes] = useState<variation>();
  // const [productAttributes, setProductAttributes] =
  //   React.useState<productAttribute[]>([]);
  const { data: wishlist } = useWishlist();
  // const reactQuery = useQueryClient();
  const wishlistArray = JSON.parse(
    window.localStorage.getItem("wishlistArray") as string
  );
  const { data: product, isLoading } = useQuery(
    ["singleProduct", productId],
    async () => {
      const res: wooProduct = WooCommerce.get(
        `products/${productId}`
      )
        .then((response: any) => {
          // setProductAttributes(response.data.attributes);

          response.data.images.length > 0 &&
            setMainImgSrc(
              response.data.images[0].src || "/logo.png"
            );
          return response.data;
        })

        .catch((error: any) => {
          console.log("error getting product", error);
        });
      return res;
    }
  );

  return (
    <div className="sm:mt-32 mt-16 flex flex-col font-montserrat h-100 p-5">
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <MyLoader />
        </div>
      ) : (
        <div className="flex justify-between lg:gap-20 gap-5 items-center lg:pr-24 lg:pl-24 p-0 lg:flex-row flex-col">
          <div className="flex flex-col gap-2 justify-center items-center w-full">
            <div className="overflow-hidden w-full flex flex-col justify-center items-center ">
              <div className="border p-5 flex justify-center items-center dark:bg-white mb-5">
                <img
                  src={mainImgSrc || "/logo.png"}
                  alt={product?.name}
                />
              </div>
              <div className="flex justify-start items-center gap-1 overflow-scroll no-scrollbar lg:w-4/6 ">
                {product?.images.map((img) => (
                  <img
                    key={img.id}
                    src={img.src}
                    alt={img.name}
                    className="w-20 cursor-pointer"
                    onClick={() => setMainImgSrc(img.src)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 w-full h-full">
            <div className="flex gap-3 flex-col">
              <Breadcrumbs
                categoryName={
                  categoryName ||
                  product?.categories[2].name
                }
                categoryId={
                  categoryId ||
                  String(product?.categories[2].id)
                }
              />

              <div className="flex justify-between gap-5">
                <h1 className="font-medium sm:text-xl text-md">
                  {product?.name?.toUpperCase()}
                </h1>
                <div className="flex justify-start gap-5 items-end mb-1">
                  <Wishlist
                    productId={product?.id || 0}
                    productName={product?.name || ""}
                    single
                    wishlist={
                      wishlist ? wishlist : wishlistArray
                    }
                  />
                  <Share2
                    onClick={() => {
                      navigator.clipboard.writeText(
                        window.location.href
                      );
                      toast({
                        title: "Link copied to clipboard",
                        action: <CopyCheck />,
                        className:
                          "fixed top-0 left-0 sm:relative",
                      });
                    }}
                    className="cursor-pointer w-5 hover:fill-neutral-900 dark:hover:fill-neutral-200"
                  />
                </div>
              </div>
            </div>

            <p className="font-bold text-xl">
              {product?.regular_price}$
            </p>
            <Link
              className="capitalize italic dark:text-neutral-400 text-neutral-600 hover:underline text-sm sm:text-md"
              to={`/shop/category/${
                categoryId || product?.categories[2].id
              }/${
                categoryName || product?.categories[2].name
              }`}
            >
              {categoryName?.toLowerCase() ||
                product?.categories[2].name.toLocaleLowerCase()}
            </Link>
            <Link
              className="italic dark:text-neutral-400 text-neutral-600 hover:underline text-sm sm:text-md"
              to={`/shop/category/${product?.tags[0]?.id}/${product?.tags[0]?.name}`}
            >
              {product?.tags[0]?.name}
            </Link>
            <p className="text-sm sm:text-md">
              {product?.description.slice(3, -5)}
            </p>
            {/* <div className="flex flex-col gap-2 w-32">
              {productAttributes?.map(
                (att: productAttribute, index: number) => (
                  <Select
                    key={index + att.id}
                    onValueChange={(e) => {
                      setAttributes((attz) => ({
                        ...(attz || {}),
                        [att.name]: e,
                      }));
                    }}
                    value={attribtues?.[att.name]}
                  >
                    <SelectTrigger className="w-full focus:outline-none">
                      <SelectValue
                        placeholder={att.name}
                        className="w-full"
                      />
                    </SelectTrigger>
                    <SelectContent className="w-full cursor-pointer">
                      {att.options.map((option) => (
                        <SelectItem
                          value={option}
                          key={option}
                        >
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )
              )}
            </div> */}
            <div className="flex items-center gap-2">
              <h5 className="font-bold">Quantity</h5>
              <Input
                type="number"
                className="w-20 focus:outline-none"
                min={1}
                value={quantity}
                onChange={(e) =>
                  setQuantity(e.target.value)
                }
              />
            </div>
            <div className="flex gap-1 justify-start items-start">
              <p className="hover:underline cursor-pointer text-neutral-400">
                Delivery & Terms{" "}
              </p>
              <ExternalLinkIcon className="h-4 w-4" />
            </div>
            <div className="sm:w-48">
              <AddToCart
                id={productId ? productId : "1"}
                quantity={quantity}
                categoryId={Number(categoryId)}
                categoryName={categoryName || ""}
                name={productName || ""}
                // variations={attribtues}
              />
            </div>
            <div className="flex flex-col gap-2 text-sm sm:text-md">
              {product?.short_description.slice(3, -5)}
            </div>
          </div>
        </div>
      )}

      {product && (
        <div className="lg:p-24 mt-10 flex flex-col gap-20">
          <HomeProducts
            similar
            title="SIMILAR PRODUCTS"
            categoryId={Number(
              categoryId || product?.categories[2].id
            )}
            productId={product.id}
          />
        </div>
      )}
    </div>
  );
}
