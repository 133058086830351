import { useNavigate } from "react-router-dom";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../src/components/ui/carousel";

interface Image {
  id: number;
  source: string;
  tagId: number;
}
interface Tag {
  id: number;
  name: string;
}
export default function HairConcern() {
  const nav = useNavigate();
  const images: Image[] = [
    { id: 1, source: "/damaged.webp", tagId: 417 },
    { id: 2, source: "/dry.webp", tagId: 386 },
    { id: 3, source: "/colored.webp", tagId: 442 },
    { id: 4, source: "/anti.jpeg", tagId: 407 },
    { id: 4, source: "/curly.webp", tagId: 444 },
    { id: 4, source: "/dull.webp", tagId: 455 },
  ];
  const tags: Tag[] = [
    { id: 417, name: "Damaged Hair" },
    { id: 386, name: "Dry Hair" },
    { id: 442, name: "Colored Hair" },
    { id: 407, name: "Anti Dandruff" },
    { id: 444, name: "Curly Hair" },
    { id: 455, name: "Dull Hair" },
  ];
  const mapTagsToImages = (
    tags: Tag[],
    images: Image[]
  ) => {
    return tags.map((tag) => ({
      ...tag,
      images: images.filter(
        (image) => image.tagId === tag.id
      ),
    }));
  };

  const tagsWithImages = mapTagsToImages(tags, images);
  // Display the tags with their associated images

  return (
    <div className="flex flex-col font-montserrat gap-5 pl-0">
      <h4 className="sm:text-2xl text-xl font-montserrat font-medium lg:text-left text-center lg:pl-5">
        HAIR CONCERN
      </h4>

      <Carousel
        opts={{ loop: false, skipSnaps: true }}
        className="relative"
      >
        <CarouselContent className="flex gap-5 ml-0">
          {tagsWithImages?.map((tag, index) => (
            <CarouselItem
              key={tag.id}
              className="basis-auto p-0"
            >
              <div
                key={tag.id}
                data-aos="fade-up"
                data-aos-delay={`${index * 100}`}
                data-aos-duration={`${index * 40}`}
                className="border border-neutral-200 sm:w-72 w-48 h-64 sm:h-96 relative cursor-pointer"
                onClick={() =>
                  nav(
                    `/shop/hairType/${
                      tag.id
                    }/${encodeURIComponent(tag.name)}`
                  )
                }
              >
                <div className="absolute bottom-0 p-5 w-full flex flex-col dark:text-neutral-900 ">
                  <span className="w-full font-montserrat z-10 sm:text-xl text-md font-light">
                    {tag.name.toLocaleUpperCase()}
                  </span>
                </div>
                <div className="absolute h-1/2 bottom-0 from-white w-full bg-gradient-to-t"></div>

                {tag.images.map((image) => (
                  <img
                    key={image.id}
                    src={image.source}
                    alt={tag.name}
                    className="h-full"
                  />
                ))}
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="z-30 left-0 absolute h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
        <CarouselNext className="absolute z-30 right-0  h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
      </Carousel>
    </div>
  );
}
