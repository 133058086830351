import { Button } from "../../../src/components/ui/button";

export default function TheArtist() {
  const phone = "+9613976780";
  const message =
    "Greetings! I would like to make an appointment with Amadeus";
  return (
    <div className="w-screen flex flex-row justify-between items-center bg-neutral-200  dark:text-neutral-900">
      <div className="flex flex-col justify-between items-center sm:justify-center text-center gap-2 sm:gap-5 font-montserrat sm:p-0 p-5 w-full">
        <img
          src="logo-aaa.png"
          alt="amadeus-logo"
          className="sm:w-20 w-10 "
        />
        <h5 className="sm:text-2xl text-xl font-montserrat font-medium">
          THE ARTIST
        </h5>
        <p className="font-light sm:text-md text-sm sm:block hidden">
          We are waiting to create magic with you
        </p>

        <Button
          onClick={() =>
            window.open(
              `https://wa.me/${phone}?text=${message}`,
              "_blank",
              "rel=noopener noreferrer"
            )
          }
          className="rounded-none sm:w-52  text-[12px] sm:text-[14px] h-full flex items-start gap-1 dark:bg-neutral-800 hover:dark:bg-neutral-700 dark:text-neutral-100"
        >
          <img
            src="/wp-icon-2.svg"
            className="w-4 invert mt-[1px]"
            alt="whatsapp"
          />

          <span className="sm:text-sm text-[12px]">
            LET'S DO MAGIC
          </span>
        </Button>
      </div>
      <div className=" flex justify-center items-end h-full w-full">
        <img
          src="artist.png"
          alt="artist-amadeus"
          className="sm:w-96 w-full"
        />
      </div>
    </div>
  );
}
