import React from "react";
import Navbar from "../navBar/navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../footer/Footer";
import ScrollToTop from "../../../src/components/scrollToTop";

export default function UserLayout() {
  const { pathname } = useLocation();
  return (
    <div className="flex flex-col justify-between min-h-screen min-w-[300px]">
      <ScrollToTop />
      <Navbar location={pathname} />
      <Outlet />
      <Footer />
    </div>
  );
}
