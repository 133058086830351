import TheArtist from "./theArtist";
import InstaHome from "./instaHome";
import HomeCollections from "./homeCollections";
import HairConcern from "./HairConcern";
import HomeBanner from "./homeBanner";
import HomeProducts from "../products/homeProducts";
import { HomeCarousel } from "./homeCarousel";
import { Testimonials } from "./testimonials";
import React from "react";
import { useTheme } from "next-themes";

export default function UserHome() {
  const { setTheme } = useTheme();
  React.useEffect(() => {
    const userTheme = String(
      localStorage.getItem("userTheme")
    );
    if (userTheme) {
      setTheme("light");
    }
  }, []);
  return (
    <div className="w-full flex flex-col lg:gap-32 gap-10">
      <HomeCarousel />
      <HomeCollections
        collectionId={368}
        title="Kerastase"
      />
      <HomeCollections
        collectionId={437}
        title="Schwarzkopf"
      />
      <HomeBanner />
      <HairConcern />
      <TheArtist />
      <HomeProducts title="RECOMMENDED BY AMADEUS" />
      <Testimonials />
      <InstaHome />
    </div>
  );
}
