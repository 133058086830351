import { ArrowLeft } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

export default function BackButton() {
  return (
    <Link
      to="/"
      className="fixed top-5 left-5 z-50 text-neutral-500 hover:text-neutral-100 flex gap-2"
    >
      <ArrowLeft className="w-4" />
      HOME
    </Link>
  );
}
