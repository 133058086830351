import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../src/components/ui/button";
import React from "react";

const sections = [
  {
    id: 1,
    title: "AMADEUS WOMEN",
    contentOne:
      "The calm is the storm itself, some days you'd go to do your hair, other times for your make up, or perhaps a soothing massage.",
    contentTwo:
      "Does your hair need extensions or is damaged by the daily elements? Perhaps you are in the mood to change your hair color, or to apply some treatment to it.",
    contentThree:
      "Maybe this is your manicure and pedicure time away from the stresses either from the family or the office.",
    contentFour:
      "Perhaps this is your big day - the wedding itself. Whereby you and the bridesmaid get to pamper yourselves for what is a once in a lifetime experience and occasion. Where you can discover new horizons in your personality, facets you have not known that existed, that make the beauty in you become the beast.",
    image: "/amadeus-women.png",
    link: "lebanonWomen",
    country: "lebanon",
    city: "chtoura and dubai",
  },
  {
    id: 2,
    title: "AMADEUS MEN",
    contentOne:
      "Gentlemen, maybe it is you longing for some beard trimming, or perhaps some anti-aging by coloring the roots of your hair or highlighting it, or getting some hair-botox or some waxing in those odd facial places.",
    image: "/amadeus-men.png",
    contentTwo: "",
    ContentThree: "",
    ContentFour: "",
    link: "lebanonMen",
    country: "lebanon",
    city: "leb",
  },
];

export default function Services() {
  const [activeSection, setActiveSection] =
    React.useState(0);
  const country = useParams().country;
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const newActiveSection = Math.floor(
        scrollPosition / windowHeight
      );
      setActiveSection(newActiveSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () =>
      window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="flex flex-col w-screen h-100 font-montserrat lg:mt-20 mt-10 gap-5">
      {/* <h2 className="text-xl sm:text-6xl font-trajan text-center font-medium lg:mb-0 mb-10 p-5">
        THE AMADEUS EXPERIENCE
      </h2> */}
      <div className="flex lg:flex-row flex-col">
        <div className="w-full md:w-1/2 h-screen sm:sticky  md:block hidden top-0">
          <img
            src={
              sections[activeSection]?.image
                ? sections[activeSection]?.image
                : ""
            }
            alt={`Section ${activeSection + 1}`}
            className={`w-full ${
              country === "dubai"
                ? "h-full object-contain"
                : "object-cover"
            }`}
          />
        </div>
        <div className="md:w-1/2">
          {sections
            .filter(
              (section) =>
                !section.city.includes("leb") ||
                country === "lebanon"
            )
            .map((section) => (
              <div
                key={section.id}
                className="md:h-screen flex flex-col justify-center sm:p-5 gap-5"
              >
                <img
                  src={section.image}
                  alt="section-amadeus-services"
                  className="md:hidden block"
                />
                <h2 className="text-3xl font-bold pl-5 pr-5 sm:p-0">
                  {section.title}
                </h2>
                <p className="text-lg pl-5 pr-5 sm:p-0">
                  {section.contentOne}
                </p>
                {section.id === 1 && (
                  <div className="gap-5 flex-col flex pl-5 pr-5 sm:p-0">
                    {" "}
                    <p className="text-lg">
                      {section.contentTwo}
                    </p>
                  </div>
                )}
                <Button
                  className="rounded-none w-fit ml-5 mb-10 sm:ml-0 sm:mb-0 w-36"
                  onClick={() => {
                    navigate(
                      `/services/lebanon/${section.link}`
                    );
                  }}
                >
                  VIEW SERIVCES
                </Button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
