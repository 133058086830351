import React from "react";
import { CartDrawer } from "../../components/cartDrawer";
import { User } from "lucide-react";
import { Link } from "react-router-dom";
import CategoriesBar from "./categoriesBar";
import Wishlist from "../../../src/components/Wishlist";
import useWishlist from "../../../src/hooks/useWishlist";
import SearchSheet from "../../../src/components/searchSheet";
import { ServicesLocation } from "./servicesLocation";
import { UserMenu } from "./userMenu";
import { ModeToggle } from "../../../src/components/themeToggle";
import { LeftDrawer } from "../../../src/components/leftDrawer";

export default function Navbar({
  location,
}: {
  location: string;
}) {
  const [switchLogo, setSwitchLogo] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [lastScrollY, setLastScrollY] = React.useState(0);

  const { data: wishlist } = useWishlist();

  const handleSwitchLogo = () => {
    if (window.scrollY > 100) {
      // Scroll down

      setSwitchLogo(true);
    } else {
      setSwitchLogo(false);
    }
  };

  React.useEffect(() => {
    const handleShowMenu = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // Scroll down
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
      setLastScrollY(currentScrollY);
    };
    window.addEventListener("scroll", handleShowMenu);
    window.addEventListener("scroll", handleSwitchLogo);

    return () => {
      window.removeEventListener("scroll", handleShowMenu);
      window.removeEventListener(
        "scroll",
        handleSwitchLogo
      );
    };
  }, [lastScrollY]);
  console.log(window.location);
  return (
    <div
      className={
        location === "/" && !switchLogo
          ? `fixed top-0 bg-transparent z-50 w-screen bg-gradient-to-t from-[#ffffff10] to-[#00000090] ${
              showMenu ? "sm:block hidden" : "block"
            }`
          : `fixed top-0 bg-white dark:bg-neutral-900 z-50 w-screen border-b ${
              showMenu ? "sm:block hidden" : "block"
            }`
      }
    >
      <div
        className={
          switchLogo
            ? `pl-3 pr-3 flex justify-between w-full items-center bg-white dark:bg-neutral-900 ${
                showMenu ? "border-b" : ""
              }`
            : "pl-3 pr-3 flex justify-between w-full items-center"
        }
      >
        <div className="flex hover:cursor-pointer gap-3 items-center">
          <LeftDrawer
            scrolled={switchLogo || location !== "/"}
          />
          <ServicesLocation
            switchLogo={switchLogo}
            location={location}
          />
        </div>
        <div className="flex w-full justify-center items-center pt-1 pb-1 pr-2 sm:p-0">
          <Link
            to="/"
            className={
              switchLogo || location !== "/"
                ? "flex justify-center items-start p-1"
                : "flex justify-center items-start p-1"
            }
          >
            <img
              src="/logo-aaa.png"
              alt="logo"
              className={
                switchLogo && showMenu
                  ? "opacity-100 duration-500 dark:invert sm:w-8 hidden sm:block ml-2"
                  : "opacity-0 pointer-events-none cursor-auto duration-500 dark:invert sm:w-8 translate-x-96 hidden sm:block"
              }
            />
          </Link>
          <Link
            to="/"
            className="flex sm:justify-center items-center sm:w-full pt-[1px]"
          >
            <img
              src="/logo-6.png"
              alt="center-logo"
              className={
                switchLogo && showMenu
                  ? "opacity-100 sm:opacity-0 pointer-events-none duration-300 dark:invert sm:pt-1 w-80 -translate-x-96"
                  : `opacity-100 duration-300 sm:pt-1 w-80 ${
                      location === "/" && !switchLogo
                        ? "invert"
                        : "dark:invert"
                    }`
              }
            />
          </Link>
        </div>
        <div className="flex gap-3 hover:cursor-pointer items-end">
          <SearchSheet
            scrolled={switchLogo || location !== "/"}
          />

          <Wishlist
            navBar
            scrolled={switchLogo || location !== "/"}
            wishlist={wishlist}
          />
          <Link
            to="/profile"
            className={
              switchLogo || location !== "/"
                ? "sm:block hidden  text-neutral-900  hover:fill-neutral-900  dark:text-neutral-200 hover:fill-neutral-100"
                : "sm:block hidden  text-neutral-100 hover:fill-neutral-200 dark:text-neutral-200 hover:fill-neutral-100"
            }
          >
            <User
              className={
                switchLogo || location !== "/"
                  ? "h-5 w-5 hover:fill-neutral-900 dark:hover:fill-neutral-200"
                  : "h-5 w-5 hover:fill-neutral-200 dark:hover:fill-neutral-200"
              }
            />
          </Link>
          <UserMenu
            scrolled={switchLogo || location !== "/"}
          />
          <CartDrawer
            scrolled={switchLogo || location !== "/"}
          />
          <div className="sm:block hidden">
            <ModeToggle
              scrolled={switchLogo || location !== "/"}
            />
          </div>
        </div>
      </div>
      <div
        className={
          location !== "/" || switchLogo
            ? `dark:bg-neutral-900 dark:text-neutral-200 bg-white font-montserrat ${
                showMenu
                  ? "hidden"
                  : "lg:block hidden border-b"
              }`
            : ` lg:block hidden font-montserrat `
        }
      >
        <CategoriesBar
          scrolled={switchLogo || location !== "/"}
        />
      </div>
    </div>
  );
}
