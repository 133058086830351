import * as React from "react";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import { QuoteIcon } from "lucide-react";
const testimonials = [
  {
    id: 1,
    testimonial:
      "The staff at this salon are incredibly professional and friendly.",
    person_name: "Emily Carter",
  },
  {
    id: 2,
    testimonial:
      "Amazing service! The facial left my skin glowing.",
    person_name: "Sophia Martinez",
  },
  {
    id: 3,
    testimonial:
      "I absolutely love the way they styled my hair for my wedding.",
    person_name: "Olivia Thompson",
  },
];

export function Testimonials() {
  return (
    <div className="flex w-screen justify-center items-center lg:pl-14 lg:pr-14 pl-12 pr-12">
      <Carousel className="w-full">
        <CarouselContent>
          {testimonials.map((testimonial) => (
            <CarouselItem key={testimonial.id}>
              <div className="gap-5 flex flex-col justify-center items-center text-center leading-relaxed">
                <div className="flex gap-5">
                  <QuoteIcon className="w-12 rotate-180" />
                  <span className="md:text-xl font-trajan">
                    {testimonial.testimonial}
                  </span>
                  <QuoteIcon className="w-12" />
                </div>
                <span className="font-semibold md:text-xl">
                  {testimonial.person_name}
                </span>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="border-0" />
        <CarouselNext className="border-0" />
      </Carousel>
    </div>
  );
}
