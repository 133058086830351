// price-list-data.ts

export interface PriceItem {
  service: string;
  junior?: number;
  senior?: number;
  prive?: number;
}

export interface PriceList {
  id: number;
  category: string;
  image: string;
  items: PriceItem[];
}

export interface hairWomenInterface {
  id: number;
  image: string;
  category: string;
  items: PriceItem[];
}
export const hairWomen = {
  id: 1,
  image: "/salon-1.jpg",
  category: "Amadeus-Hair Women",
  items: [
    {
      service: "Hair-Wash",
      junior: 8,
      senior: 10,
      prive: 25,
    },
    {
      service: "Blow-Dry Short Hair",
      junior: 7,
      senior: 10,
      prive: 30,
    },
    {
      service: "Blow-Dry Medium Hair",
      junior: 10,
      senior: 14,
      prive: 40,
    },
    {
      service: "Blow-Dry Long Hair",
      junior: 14,
      senior: 17,
      prive: 50,
    },
    {
      service: "Curling Short Hair",
      junior: 10,
      senior: 14,
      prive: 45,
    },
    {
      service: "Curling Medium Hair",
      junior: 14,
      senior: 17,
      prive: 55,
    },
    {
      service: "Curling Long Hair",
      junior: 17,
      senior: 20,
      prive: 65,
    },
    {
      service: "Half-Updo",
      junior: 30,
      senior: 40,
      prive: 100,
    },
    {
      service: "Updo",
      junior: 40,
      senior: 50,
      prive: 150,
    },
    {
      service: "Retro Short Hair",
      junior: 30,
      senior: 40,
      prive: 100,
    },
    {
      service: "Retro Long Hair",
      junior: 40,
      senior: 50,
      prive: 150,
    },
    {
      service: "Wet Look",
      junior: 30,
      senior: 35,
      prive: 80,
    },
    {
      service: "Braiding",
      junior: 20,
      senior: 30,
      prive: 100,
    },
    {
      service: "Hair-Cut",
      junior: 20,
      senior: 30,
      prive: 70,
    },
    {
      service: "Fringe-Cut",
      junior: 8,
      senior: 10,
      prive: 35,
    },
    {
      service: "Split-Ends Trimming",
      junior: 10,
      senior: 20,
      prive: 50,
    },
    {
      service: "Princess-Cut",
      junior: 10,
      senior: 15,
      prive: 50,
    },
    {
      service: "Fusio-Dose Treatment Kerastase",
      junior: 30,
      senior: 45,
      prive: 100,
    },
    {
      service: "Fusio-Scrub Treatment Kerastase",
      junior: 20,
      senior: 30,
      prive: 60,
    },
    {
      service: "Mix-Treatment Kerastase",
      junior: 45,
      senior: 65,
      prive: 130,
    },
    {
      service: "Chrono-Rituel Kerastase",
      junior: 60,
      senior: 80,
      prive: 160,
    },
    {
      service: "Extentioniste-Protocol Kerastase",
      junior: 35,
      senior: 45,
      prive: 100,
    },
    {
      service: "K-Water Treatment Kerastase",
      junior: 10,
      senior: 15,
      prive: 35,
    },
    {
      service: "Fiber-Clinix Schwarzkopf Treatment",
      junior: 20,
      senior: 30,
      prive: 60,
    },
    {
      service: "Fiber-Plex Schwarzkopf Bonding Treatment",
      junior: 25,
      senior: 35,
      prive: 60,
    },
    { service: "R2 Treatment", junior: 20 },
    { service: "Detox", junior: 0.25 },
    {
      service: "HairColor Roots",
      junior: 35,
      senior: 50,
      prive: 100,
    },
    {
      service: "HairColor",
      junior: 15,
      senior: 20,
      prive: 50,
    },
    {
      service: "HairColor Roots Amonia Free",
      junior: 40,
      senior: 60,
      prive: 120,
    },
    {
      service: "HairColor Amonia Free",
      junior: 18,
      senior: 25,
      prive: 50,
    },
    {
      service: "Bleaching Highlight/Lowlight",
      junior: 35,
      senior: 50,
      prive: 110,
    },
    {
      service: "Toner",
      junior: 25,
      senior: 35,
      prive: 70,
    },
    {
      service: "Highlift Color",
      junior: 45,
      senior: 60,
      prive: 130,
    },
    {
      service: "Hair-Botox Glossing Treatment",
      junior: 65,
      senior: 90,
    },
    {
      service: "Hair-Keratin Straightening Treatment",
      junior: 65,
      senior: 90,
    },
  ],
};

export const princessServices = {
  id: 2,
  image: "/salon-2.jpg",
  category: "Amadeus-Princess Services",
  items: [
    {
      service: "Princess Half Updo",
      junior: 15,
      senior: 20,
    },
    { service: "Princess Updo", junior: 20, senior: 25 },
    {
      service: "Princess Blow Dry short",
      junior: 3,
      senior: 5,
    },
  ],
};

export const makeupLab = {
  id: 3,
  image: "/salon-3.jpg",
  category: "Amadeus-Makeup Lab",
  items: [
    {
      service: "Regular Makeup",
      junior: 40,
      senior: 65,
      prive: 200,
    },
    {
      service: "Lashes",
      junior: 10,
      senior: 15,
      prive: 45,
    },
    { service: "Brow-Shaping", junior: 5, prive: 50 },
    { service: "Upperlip", junior: 2 },
    { service: "Lash-Lifting", junior: 35 },
    { service: "Brow Lamination", junior: 35 },
    { service: "Rejuvi Tattoo Removal", junior: 200 },
    {
      service: "Micro-Blading Eyebrow Tattoo",
      junior: 350,
    },
    {
      service: "Lip Contour/Filling Tattoo",
      junior: 350,
    },
  ],
};

export const bridal = {
  id: 4,
  image: "/salon-4.jpg",
  category: "Amadeus-Bridal",
  items: [
    {
      service: "Bridal Hairstyle",
      junior: 350,
      senior: 700,
    },
    {
      service: "Bridesmaid Hairstyle",
      junior: 200,
      senior: 500,
    },
    {
      service: "Engagement Hairstyle",
      junior: 200,
      senior: 500,
    },
    {
      service: "Bridal Makeup",
      junior: 400,
      senior: 800,
    },
    {
      service: "Bridesmaid Makeup",
      junior: 250,
      senior: 600,
    },
    {
      service: "Engagement Makeup",
      junior: 250,
      senior: 600,
    },
  ],
};

export const nailLab = {
  id: 5,
  image: "/salon-1.jpg",
  category: "Amadeus-Nail Lab",
  items: [
    {
      service: "Regular Manicure without Gel Polish",
      junior: 8,
    },
    {
      service: "Regular Pedicure without Gel Polish",
      junior: 10,
    },
    {
      service: "Regular Manicure with Gel Polish",
      junior: 15,
    },
    {
      service: "Regular Pedicure with Gel Polish",
      junior: 20,
    },
    {
      service: "Basic Design/Sticker per Nail",
      junior: 1,
    },
    { service: "Regular Nail Polish", junior: 4 },
    { service: "French Nail Polish", junior: 6 },
    { service: "Regular Fake Nails", junior: 12 },
    {
      service: "Russian Manicure without Gel Polish",
      junior: 8,
    },
    {
      service: "Russian Pedicure without Gel Polish",
      junior: 10,
    },
    {
      service: "Russian Manicure with Gel Polish",
      junior: 15,
    },
    {
      service: "Russian Pedicure with Gel Polish",
      junior: 20,
    },
    { service: "Remove Gel Nail Polish", junior: 3 },
    {
      service:
        "Full set extensions including Russian Manicure",
      junior: 40,
    },
    { service: "Gel Extension Refill", junior: 20 },
    {
      service: "Gel Extension Refill per Nail",
      junior: 3,
    },
    { service: "Remover Acrylic", junior: 5 },
    { service: "Hands Scrubbing", junior: 5 },
    { service: "Feet Scrubbing", junior: 7 },
    { service: "Hands Paraffin", junior: 5 },
    { service: "Feet Paraffin", junior: 7 },
  ],
};

export const spaWomen = {
  id: 6,
  image: "/salon-1.jpg",
  category: "Amadeus-SPA Women",
  items: [
    { service: "Neck Wax", junior: 5 },
    { service: "Ear Wax", junior: 3 },
    { service: "Nose Wax", junior: 3 },
    { service: "Forehead Wax", junior: 3 },
    { service: "Cheek Wax", junior: 3 },
    { service: "Full Face Wax", junior: 8 },
    { service: "Full Body Wax", junior: 35 },
    { service: "Extra Full Body Wax", junior: 40 },
    { service: "Half Arms Wax", junior: 5 },
    { service: "Full Arms Wax", junior: 10 },
    { service: "Half Legs Wax", junior: 8 },
    { service: "Full Legs Wax", junior: 15 },
    { service: "Under Arms Wax", junior: 5 },
    { service: "Stomach Line Wax", junior: 3 },
    { service: "Full Stomach Wax", junior: 5 },
    { service: "Full Back Wax", junior: 10 },
    { service: "Full Face Threading", junior: 5 },
    { service: "Brazilian Wax", junior: 7 },
    { service: "Bikini Wax", junior: 5 },
    { service: "Hands Massage 15 min", junior: 5 },
    { service: "Feet Massage 15 min", junior: 8 },
    { service: "Back & Neck Massage 30 min", junior: 20 },
    {
      service: "Aromatherapy Massage 60 min",
      junior: 30,
    },
    { service: "Deep Tissue Massage 60 min", junior: 35 },
    { service: "Reflexology Massage 60 min", junior: 70 },
    { service: "Hot Stone Massage", junior: 10 },
    { service: "Full Body-Scrubbing 25 min", junior: 15 },
    {
      service: "Full Body-Exfoliation 25 min",
      junior: 15,
    },
    {
      service: "Massage for drainage 60 min",
      junior: 40,
    },
    {
      service: "Cellulite Massage - Stomach 20 min",
      junior: 15,
    },
    {
      service: "Cellulite Massage - Full legs 30 min",
      junior: 20,
    },
    {
      service: "Cellulite Massage- Stomach & Legs 50 min",
      junior: 30,
    },
    { service: "Solarium per 10 min", junior: 5 },
  ],
};

export const priceListMenData = [
  {
    id: 1,
    image: "/salon-1.jpg",
    category: "HAIR",
    items: [
      { service: "HairCut", junior: 10 },
      { service: "Beard Trimming", junior: 5 },
      {
        service: "Beard Shaping & Styling Hairstyling",
        junior: 5,
      },
      { service: "Hairstyling", junior: 5 },
      { service: "Prince-Cut Face", junior: 8 },
      { service: "Mask Face", junior: 3 },
      { service: "Scrubbing", junior: 3 },
      {
        service: "Fusio-Dose Treatment Kerastase",
        junior: 30,
      },
      {
        service: "Fusio-Scrub Treatment Kerastase",
        junior: 20,
      },
      { service: "Mix-Treatment Kerastase", junior: 45 },
      { service: "Chrono-Rituel Kerastase", junior: 60 },
      {
        service: "Extentioniste-Protocol Kerastase",
        junior: 35,
      },
      {
        service: "K-Water Treatment Kerastase",
        junior: 10,
      },
      {
        service: "Fiber-Clinix Schwarzkopf Treatment",
        junior: 20,
      },
      {
        service: "Fiber-Plex Schwarzkopf bonding Treatment",
        junior: 25,
      },
      { service: "HairColor Roots", junior: 35 },
      { service: "HairColor", junior: 15 },
      {
        service: "HairColor Roots Amonia Free",
        junior: 40,
      },
      {
        service: "HairColor Amonia Free Bleaching",
        junior: 18,
      },
      { service: "Highlight/Lolight", junior: 0.35 },
      { service: "Highlift Color", junior: 45 },
      { service: "Hair-Botox starting", junior: 65 },
      {
        service: "Hair Botox Glossing Treatment",
        junior: 0.6,
      },
      { service: "Hair-Keratin Starting", junior: 65 },
      {
        service: "Hair-Keratin straightening Treatment",
        junior: 0.6,
      },
    ],
  },
  {
    id: 2,
    image: "/salon-2.jpg",
    category: "SPA",
    items: [
      { service: "Brow Wax", junior: 10 },
      { service: "Neck Wax", junior: 7 },
      { service: "Ear Wax", junior: 7 },
      { service: "Nose Wax", junior: 7 },
      { service: "Forehead Wax", junior: 8 },
      { service: "Cheek Wax", junior: 8 },
      { service: "Full Face Wax", junior: 20 },
      { service: "Full face Threading", junior: 15 },
      { service: "Regular Manicure", junior: 15 },
      { service: "Regular Pedicure", junior: 20 },
      { service: "Russian Manicure-Men", junior: 20 },
      { service: "Russian Pedicure-Men", junior: 25 },
      { service: "Hands Scrubbing", junior: 12 },
      { service: "Feet Scrubbing", junior: 18 },
      { service: "Hands Paraffin", junior: 18 },
      { service: "Feet Paraffin", junior: 25 },
      { service: "Hands Massage 15 min", junior: 5 },
      { service: "Feet Massage 15 min", junior: 8 },
      { service: "Back & Neck Massage 30 min", junior: 20 },
      { service: "Swedish Massage 50 min" },
      { service: "Aromatherapy Massage 50 min" },
      { service: "Deep Tissue Massage 50 min" },
      { service: "Reflexology Massage 50 min" },
      { service: "Hot Stone" },
      { service: "Full Body-Scrubbing 15 min" },
      { service: "Full Body-Exfoliation 15 min" },
      { service: "Solarium per 10 min", junior: 5 },
    ],
  },
];
