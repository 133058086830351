import React from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";

import { ShoppingCart } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Button } from "./ui/button";
import MyLoader from "./MyLoader";
import RemoveFromCart from "./removeFromCart";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { cartItem } from "../pages/products/productInterface";
import { apiCall } from "../../src/lib/api";

export function CartDrawer({
  scrolled,
}: {
  scrolled: boolean;
}) {
  const nav = useNavigate();
  const [cookies] = useCookies(["cartKey", "userToken"]);

  const { data: cartItems, isLoading } = useQuery(
    ["cartItems"],
    async () => {
      const key = await cookies.cartKey;
      const res = await fetch(
        !cookies.userToken
          ? `${apiCall}/wp-json/cocart/v2/cart/?cart_key=${key}`
          : `${apiCall}/wp-json/cocart/v2/cart/`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: !cookies.userToken
              ? ""
              : `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
      }
      const response = await res.json();
      return response;
    },
    { enabled: !!cookies.cartKey || !!cookies.userToken }
  );

  return (
    <Sheet>
      <SheetTrigger
        className="flex justify-center items-center relative"
        name="cart drawer"
      >
        <ShoppingCart
          className={
            scrolled
              ? "h-5 w-5 text-neutral-900 hover:fill-neutral-900 dark:text-neutral-200 dark:hover:fill-neutral-200"
              : "h-5 w-5 text-neutral-100 hover:fill-neutral-200 dark:text-neutral-200 hover:fill-neutral-200"
          }
        />
        <p
          className={
            cartItems?.items?.length > 0
              ? `sm:block hidden absolute -top-3 ${
                  scrolled
                    ? `bg-neutral-900 text-neutral-200 border-neutral-900 dark:bg-neutral-200 dark:border-neutral-200 dark:text-neutral-900`
                    : `bg-neutral-200 text-neutral-900 border-neutral-200 dark:bg-neutral-200 dark:border-neutral-200 dark:bg-neutral-200 dark:text-neutral-900`
                } -right-2 border rounded-full pl-1 pr-1 text-[9px]`
              : "hidden"
          }
        >
          {cartItems?.items?.length > 0
            ? cartItems?.items?.length
            : ""}
        </p>
      </SheetTrigger>

      <SheetContent
        side={"right"}
        className="flex flex-col justify-between p-5"
      >
        <SheetHeader>
          <SheetTitle className="text-2xl font-montserrat ">
            CART
          </SheetTitle>
        </SheetHeader>
        {cartItems?.items?.length > 0 ? (
          <div className="overflow-scroll no-scrollbar h-[650px] flex flex-col gap-2 font-montserrat">
            {isLoading ? (
              <MyLoader />
            ) : (
              cartItems?.items?.map((product: cartItem) => {
                return (
                  <Card
                    key={product?.id}
                    className="flex justify-between rounded-none"
                  >
                    <SheetClose>
                      <CardHeader
                        className="flex flex-row p-0 cursor-pointer"
                        onClick={() =>
                          nav(
                            `product/${product.id}/${product.name}`
                          )
                        }
                      >
                        <div className="h-full flex justify-center items-center w-1/3 dark:bg-white p-3">
                          <img
                            src={
                              product?.featured_image ||
                              "/logo.png"
                            }
                            alt=""
                            className={"w-32"}
                          />
                        </div>
                        <CardTitle className="pl-1 flex gap-2 flex-col text-sm sm:w-64 w-28">
                          <p className="text-[12px] line-clamp-2 text-left">
                            {product?.name.toLocaleUpperCase()}
                          </p>

                          <CardDescription className="text-[12px] truncate font-light text-left">
                            {String(product.quantity.value)}{" "}
                            x ${product.price} = $
                            {Number(
                              product.quantity.value
                            ) * Number(product.price)}
                          </CardDescription>
                        </CardTitle>
                      </CardHeader>
                    </SheetClose>
                    <CardFooter className="flex flex-col justify-between items-end p-2 w-1/6">
                      <p className="text-sm font-montserrat font-bold">
                        {product?.price}$
                      </p>

                      <RemoveFromCart
                        itemId={String(product.item_key)}
                        name={product.name}
                      />
                    </CardFooter>
                  </Card>
                );
              })
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center gap-5 font-montserrat">
            <p className="text-center ">
              Your cart is empty!
            </p>
            <SheetClose asChild>
              <Button
                onClick={() => nav("/shop")}
                className="rounded-none w-36"
              >
                SHOP NOW
              </Button>
            </SheetClose>
          </div>
        )}
        <div className="w-full pt-5 flex flex-col justify-between gap-3">
          <Card className="w-full rounded-none">
            <CardHeader>
              <CardTitle className="border-b pb-2 sm:text-xl font-montserrat text-md ">
                CART DETAILS
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-5 font-montserrat">
              <div className="flex justify-between ">
                <p className="sm:text-md text-sm">
                  SUBTOTAL
                </p>
                <p className="sm:text-md text-sm">
                  {cartItems?.totals?.subtotal
                    ? cartItems?.totals?.subtotal
                    : "0"}
                  $
                </p>
              </div>
              <div className="flex justify-between font-bold">
                <p className="sm:text-md text-sm">TOTAL</p>
                <p className="sm:text-md text-sm">
                  {cartItems?.totals?.total
                    ? cartItems?.totals?.total
                    : "0"}
                  $
                </p>
              </div>
            </CardContent>
          </Card>
          <SheetClose asChild>
            <Button
              onClick={() => nav("/cart")}
              className="w-full font-montserrat rounded-none"
              variant={"secondary"}
            >
              VIEW CART
            </Button>
          </SheetClose>
          <SheetClose asChild>
            <Button
              onClick={() => nav("/checkout")}
              className="w-full font-montserrat rounded-none"
              disabled={cartItems?.items?.length < 1}
            >
              CHECKOUT
            </Button>
          </SheetClose>
        </div>
      </SheetContent>
    </Sheet>
  );
}
