import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import React from "react";

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  const [showIcon, setShowIcon] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position > 100) {
        setShowIcon(true);
      } else {
        setShowIcon(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <a
      href={
        "https://wa.me/+9613976780?text=Greetings!%20I%20would%20like%20to%20make%20an%20appointment"
      }
      className={`fixed right-5 bottom-6 z-50 rounded-full dark:bg-neutral-800 bg-white ${
        showIcon ? " block" : " hidden"
      }`}
      rel="noreferrer"
      target="_blank"
    >
      <img
        src="/wp-icon-2.svg"
        alt="wp-icon-2"
        className="w-7 dark:invert"
      />
    </a>
  );
};

export default ScrollToTop;
