import { HeartIcon, LoaderIcon } from "lucide-react";
import React from "react";
import { useCookies } from "react-cookie";
import { useQueryClient } from "react-query";
import { apiCall } from "../lib/api";
import { toast } from "./ui/use-toast";
import { useNavigate } from "react-router-dom";

export default function Wishlist({
  wishlist,
  productId,
  productName,
  single,
  navBar,
  scrolled,
}: {
  wishlist?: any;
  productId?: number;
  productName?: string;
  single?: boolean;
  navBar?: boolean;
  scrolled?: boolean;
}) {
  const [cookies] = useCookies(["userToken"]);
  const reactQuery = useQueryClient();
  const [loading, setLoading] = React.useState(false);
  const wishlistId =
    window.localStorage.getItem("wishlist_id");
  const nav = useNavigate();
  const refetchWishlist = () => {
    reactQuery.refetchQueries(["LoggedInwishlist"]);
  };
  const saveItemToWishlist = async () => {
    setLoading(true);
    if (cookies.userToken) {
      try {
        const res = await fetch(
          `${apiCall}/wp-json/yith/wishlist/v1/wishlists/${
            wishlistId
              ? `${wishlistId}/product/${productId}`
              : `0/product/${productId}`
          }`,
          {
            method: "post",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${cookies.userToken}`,
            },
          }
        );
        if (!res.ok) {
          setLoading(false);
          throw new Error(`HTTP error: ${res.status}`);
        }
        const response = await res.json();
        !wishlistId &&
          localStorage.setItem("wishlist_id", response.id);
        cookies.userToken && refetchWishlist();
        setLoading(false);
        toast({
          title: `${productName} added to wishlist!`,
          action: (
            <HeartIcon className="w-5" fill="black" />
          ),
          className: "fixed top-0 left-0 sm:relative",
        });
        return response;
      } catch (error) {
        console.error(
          "Failed to save item to wishlist",
          error
        );
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      let myArray = JSON.parse(
        window.localStorage.getItem("wishlistArray") || "[]"
      );
      if (
        !myArray.some(
          (item: any) => item.prod_id === String(productId)
        )
      ) {
        myArray.push({ prod_id: String(productId) });
        toast({
          title: `${productName} added to wishlist!`,
          action: (
            <HeartIcon className="w-5" fill="black" />
          ),
          className: "fixed top-0 left-0 sm:relative",
        });
        localStorage.setItem(
          "wishlistArray",
          JSON.stringify(myArray)
        );
        refetchWishlist();
        setLoading(false);
      }
    }
    reactQuery.refetchQueries(["homeProducts"]);
    cookies.userToken && refetchWishlist();
  };
  const removeItemFromWishlist = async () => {
    if (!cookies.userToken) {
      const storedArrayString =
        window.localStorage.getItem("wishlistArray");
      let res: { prod_id: number }[] = storedArrayString
        ? JSON.parse(storedArrayString)
        : [];

      // Find the index of the product in the array
      // Assuming you have the productId defined elsewhere
      const index = res.findIndex((item) => {
        return Number(item.prod_id) === productId;
      });

      if (index > -1) {
        // Only splice array when item is found
        res.splice(index, 1);
        // Save the updated array back to localStorage
        window.localStorage.setItem(
          "wishlistArray",
          JSON.stringify(res)
        );
        refetchWishlist();
        toast({
          title: `${productName} removed from wishlist!`,
          action: (
            <HeartIcon className="w-5" fill="black" />
          ),
          className: "fixed top-0 left-0 sm:relative",
        });
      }
      reactQuery.refetchQueries([
        "singleProduct",
        productId,
      ]);
      return;
    } else {
      setLoading(true);
      const res = await fetch(
        `${apiCall}/wp-json/yith/wishlist/v1/wishlists/${wishlistId}/product/${productId}`,
        {
          method: "delete",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      if (res.status === 422) {
      }
      const response = await res.json();

      refetchWishlist();
      reactQuery.refetchQueries(["homeProducts"]);
      reactQuery.refetchQueries([
        "singleProduct",
        productId,
      ]);

      setLoading(false);

      toast({
        title: `${productName} removed from wishlist!`,
        action: <HeartIcon className="w-5" fill="black" />,
        className: "fixed top-0 left-0 sm:relative",
      });

      return response;
    }
  };

  return !navBar ? (
    loading ? (
      <LoaderIcon
        className={
          single
            ? "animate-spin w-4 h-4"
            : "animate-spin w-4 h-4 dark:text-neutral-900"
        }
      />
    ) : (
      <>
        {wishlist && wishlist.length > 0 ? (
          wishlist.some(
            (wish: any) =>
              wish.prod_id === String(productId)
          ) ? (
            <HeartIcon
              className={
                single
                  ? "w-5 h-5 hover:fill-neutral-700 dark:hover:fill-neutral-400 fill-neutral-900 dark:text-neutral-200 dark:fill-neutral-200 cursor-pointer"
                  : "w-5 h-5 dark:text-neutral-900 cursor-pointer dark:fill:text-neutral-900 hover:fill-white fill-neutral-900"
              }
              onClick={removeItemFromWishlist}
            />
          ) : (
            <HeartIcon
              className={
                single
                  ? "w-5 h-5 cursor-pointer hover:fill-neutral-200  dark:text-neutral-200 hover:fill-neutral-900 dark:hover:fill-neutral-200"
                  : "w-5 h-5 hover:fill-neutral-900 cursor-pointer text-neutral-900 dark:hover:fill-neutral-900 "
              }
              onClick={saveItemToWishlist}
            />
          )
        ) : (
          <HeartIcon
            className={
              single
                ? "w-5 h-5 cursor-pointer hover:fill-neutral-200  dark:text-neutral-200 hover:fill-neutral-900 dark:hover:fill-neutral-200 "
                : "w-5 h-5 hover:fill-neutral-900  cursor-pointer text-neutral-900  dark:hover:fill-neutral-900 "
            }
            onClick={saveItemToWishlist}
          />
        )}
      </>
    )
  ) : (
    <div className="relative sm:block hidden">
      <HeartIcon
        onClick={() => nav("/wishlist")}
        className={
          scrolled
            ? "dark:hover:fill-neutral-200 h-5 w-5 text-neutral-900 hover:fill-neutral-900 dark:text-neutral-200 hover:fill-neutral-100"
            : "h-5 w-5 text-neutral-100 hover:fill-neutral-200 dark:text-neutral-200 hover:fill-neutral-100"
        }
      />
      <p
        className={
          wishlist?.length > 0
            ? `sm:block hidden absolute -top-3 aspect-square ${
                scrolled
                  ? `bg-neutral-900 text-neutral-200 border-neutral-900 dark:bg-neutral-200 dark:border-neutral-200 dark:text-neutral-900`
                  : `bg-neutral-200 text-neutral-900 border-neutral-200 dark:bg-neutral-200 dark:border-neutral-200 dark:bg-neutral-200 dark:text-neutral-900`
              } -right-2 border rounded-full  pl-1 pr-1 text-[9px]`
            : "hidden"
        }
      >
        {wishlist?.length > 0 ? wishlist?.length : ""}
      </p>
    </div>
  );
}
