import { Input } from "../../components/ui/input";
import React from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { CheckIcon, User2Icon } from "lucide-react";
import { useCookies } from "react-cookie";
import { wpUserUpdate } from "@/src/lib/interfaces";
import { useQueryClient } from "react-query";
import { toast } from "../../components/ui/use-toast";
import { apiCall } from "../../../src/lib/api";

export default function EditProfile({
  userInfo,
}: {
  userInfo: wpUserUpdate;
}) {
  const [cookies] = useCookies(["userToken"]);
  const [updatedFirstname, setUpdatedFirstname] =
    React.useState<string>(userInfo.first_name || "");
  const [updatedLastname, setUpdatedLastname] =
    React.useState<string>(userInfo.last_name || "");
  const [updatedEmail, setUpdatedEmail] =
    React.useState<string>(userInfo.email || "");
  const reactQuery = useQueryClient();
  const updatedUser: wpUserUpdate = {
    id: userInfo.id,
    first_name: updatedFirstname || userInfo.first_name,
    last_name: updatedLastname || userInfo.last_name,
    email: updatedEmail || userInfo.email,
  };
  const updateProfile = async (
    {
      user,
    }: {
      user: wpUserUpdate;
    },
    token: string
  ) => {
    try {
      const response = await fetch(
        `${apiCall}/wp-json/wp/v2/users/${user.id}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            updatedUser,
          }),
        }
      );
      const data = await response.json();
      reactQuery.refetchQueries([
        "users/me",
        cookies.userToken,
      ]);
      toast({
        title: "Edit Profile",
        description: "Profile changed successfully!",
        action: <CheckIcon />,
        className: "fixed top-0 left-0 sm:relative",
      });
      return data;
    } catch (error) {
      console.error("Error", error);
    }
  };
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="flex justify-start w-full border gap-2 rounded-none"
        >
          <User2Icon className="w-4 h-4" />
          <p>EDIT PROFILE</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit profile</DialogTitle>
          <DialogDescription>
            Make changes to your profile here. Click save
            when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label
              htmlFor="firstName"
              className="text-right"
            >
              First Name
            </Label>
            <Input
              id="firstName"
              className="col-span-3 focus:outline-none"
              value={updatedFirstname}
              onChange={(e) =>
                setUpdatedFirstname(e.target.value)
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label
              htmlFor="lastName"
              className="text-right"
            >
              Last Name
            </Label>
            <Input
              id="lastName"
              className="col-span-3 focus:outline-none"
              value={updatedLastname}
              onChange={(e) =>
                setUpdatedLastname(e.target.value)
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Email
            </Label>
            <Input
              id="email"
              className="col-span-3 focus:outline-none"
              type="email"
              value={updatedEmail}
              onChange={(e) =>
                setUpdatedEmail(e.target.value)
              }
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="submit"
              className="rounded-none"
              onClick={() =>
                updateProfile(
                  {
                    user: updatedUser,
                  },
                  cookies.userToken
                )
              }
            >
              SAVE CHANGES
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
